.main-container {
    background-attachment: fixed;
    background-image: url("../../assets/images/login_page_bg.png");
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    scrollbar-width: thin;
    -ms-overflow-style: none;
}

.main-container::-webkit-scrollbar {
    width: 0.5rem;
}

.main-container::-webkit-scrollbar-thumb {
    background-color: #53FDFD;
}

@keyframes colorChange {
    0% {
        background-color: blue;
    }

    25% {
        background-color: #e312bd;
    }

    50% {
        background-color: green;
    }

    75% {
        background-color: orange;
    }

    100% {
        background-color: blue;
    }
}

.colorchange {
    animation-name: colorChange;
    animation-duration: 6s;
    animation-iteration-count: infinite;
}