.text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.container {
    left: 50%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 500px;
}

.container:after {
    position: absolute;
    content: "";
    background-color: rgba(19, 36, 44, 0.1);
    border-radius: 12px;
    bottom: 40px;
    height: 12px;
    left: 80px;
    width: 350px;
    z-index: -1;
    animation: shadow-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
    animation-delay: 0.1s;
}

/*/////////////////// caveman */
.caveman {
    height: 300px;
    position: absolute;
    width: 250px;
}

.caveman:nth-child(1) {
    right: 20px;
}

.caveman:nth-child(2) {
    left: 20px;
    transform: rotateY(180deg);
}

.head {
    position: absolute;
    background-color: #13242C;
    border-radius: 50px;
    height: 140px;
    left: 60px;
    top: 25px;
    width: 65px;
}

.head:after,
.head:before {
    content: "";
    position: absolute;
    background-color: #13242C;
    border-radius: 10px;
    height: 20px;
    width: 7px;
}

.head:after {
    left: 35px;
    top: -8px;
    transform: rotate(20deg);
}

.head:before {
    left: 30px;
    top: -8px;
    transform: rotate(-20deg);
}

.head .eye {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    background-color: #EAB08C;
    border-radius: 50px;
    height: 16px;
    /* left: 45%; */
    top: 40px;
    width: 48px;
}

.head .eye:after,
.head .eye:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #13242C;
    border-radius: 50%;
    height: 5px;
    width: 5px;
}

.head .eye:after {
    left: 5px;
}

.head .eye:before {
    right: 9px;
}

.head .eye .nose {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #D9766C;
    border-left: 8px solid rgba(19, 36, 44, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    height: 35px;
    /* left: 45%; */
    /* top: 12px; */
    width: 15px;
}

.shape {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    border-radius: 50%;
    height: 140px;
    overflow: hidden;
    top: 70px;
    width: 140px;
}

.shape .circle {
    position: absolute;
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.shape .circle:after,
.shape .circle:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.shape .circle:after {
    left: 50px;
    top: 10px;
}

.shape .circle:before {
    left: 60px;
    top: 45px;
}

.shape .circle:nth-child(1) {
    left: -12px;
    top: 80px;
}

.shape .circle:nth-child(2) {
    right: 10px;
    top: 0px;
    transform: rotate(90deg);
}

.shape .circle:nth-child(2):after {
    left: 65px;
    top: 10px;
}

.shape .circle:nth-child(2):before {
    display: none;
}

.caveman:nth-child(1) .shape {
    background-color: #D13433;
}

.caveman:nth-child(1) .shape .circle {
    background-color: #932422;
}

.caveman:nth-child(1) .shape .circle:after,
.caveman:nth-child(1) .shape .circle:before {
    background-color: #932422;
}

.caveman:nth-child(2) .shape {
    background-color: #932422;
}

.caveman:nth-child(2) .shape .circle {
    background-color: #D13433;
}

.caveman:nth-child(2) .shape .circle:after,
.caveman:nth-child(2) .shape .circle:before {
    background-color: #D13433;
}

.arm-right {
    position: absolute;
    background-color: #EAB08C;
    border-left: 8px solid rgba(19, 36, 44, 0.1);
    border-radius: 50px;
    box-sizing: border-box;
    height: 180px;
    left: 135px;
    top: 80px;
    transform-origin: 30px 30px;
    width: 60px;
    z-index: 1;
}

.arm-right .club {
    position: absolute;
    border-bottom: 110px solid #601513;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    height: 0;
    left: -60px;
    top: 120px;
    transform: rotate(70deg);
    width: 20px;
}

.arm-right .club:after,
.arm-right .club:before {
    position: absolute;
    content: "";
    background-color: #601513;
    border-radius: 50%;
    left: 0;
}

.arm-right .club:after {
    height: 20px;
    width: 20px;
    top: -10px;
}

.arm-right .club:before {
    height: 40px;
    width: 40px;
    left: -10px;
    top: 90px;
}

.leg {
    position: absolute;
    border-radius: 10px;
    height: 55px;
    top: 200px;
    width: 10px;
}

.leg:after {
    position: absolute;
    content: "";
    border-radius: 50%;
    height: 10px;
    left: -5px;
    top: 15px;
    width: 10px;
}

.leg .foot {
    position: absolute;
    border-radius: 25px 25px 0 0;
    height: 25px;
    left: -38px;
    top: 30px;
    width: 50px;
}

.leg .foot:after,
.leg .foot:before,
.leg .foot .fingers,
.leg .foot .fingers:after {
    position: absolute;
    background-color: #EAB08C;
    border-radius: 50%;
    bottom: 0px;
    height: 15px;
    transform-origin: bottom;
    width: 15px;
}

.leg .foot:after {
    left: -6px;
    content: "";
}

.leg .foot:before {
    left: 8px;
    transform: scale(0.6);
    content: "";
}

.leg .foot .fingers {
    left: 15px;
    transform: scale(0.6);
}

.leg .foot .fingers:after {
    left: 11px;
    content: "";
}

.leg:nth-child(1) {
    background-color: #B2524D;
    left: 95px;
}

.leg:nth-child(1):after {
    background-color: #B2524D;
}

.leg:nth-child(1) .foot {
    background-color: #B2524D;
}

.leg:nth-child(1) .foot:after {
    background-color: #B2524D;
}

.leg:nth-child(1) .foot:before {
    display: none;
}

.leg:nth-child(2) {
    background-color: #D9766C;
    left: 115px;
}

.leg:nth-child(2):after {
    background-color: #D9766C;
}

.leg:nth-child(2) .foot {
    background-color: #D9766C;
}

/*/////////////////// animation */
.caveman:nth-child(1) .arm-right {
    animation: arm-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
}

.caveman:nth-child(2) .arm-right {
    animation: arm-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
    animation-delay: 0.6s;
}

@keyframes arm-anima {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-360deg);
    }
}

.caveman:nth-child(2) .head {
    animation: head-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
}

.caveman:nth-child(1) .head {
    animation: head-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
    animation-delay: 0.6s;
}

@keyframes head-anima {
    0% {
        top: 25px;
    }

    42% {
        top: 25px;
    }

    45% {
        top: 50px;
    }

    100% {
        top: 25px;
    }
}

.caveman:nth-child(2) .eye:after,
.caveman:nth-child(2) .eye:before {
    animation: eye-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
}

.caveman:nth-child(1) .eye:after,
.caveman:nth-child(1) .eye:before {
    animation: eye-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
    animation-delay: 0.6s;
}

@keyframes eye-anima {
    0% {
        height: 5px;
    }

    42% {
        height: 5px;
    }

    45% {
        height: 1px;
    }

    100% {
        height: 5px;
    }
}

@keyframes shadow-anima {
    0% {
        width: 350px;
        left: 80px;
    }

    25% {
        width: 450px;
        left: 80px;
    }

    50% {
        width: 350px;
        left: 80px;
    }

    75% {
        width: 450px;
        left: 0px;
    }

    100% {
        width: 350px;
        left: 80px;
    }
}

/*/////////////////////// credit ////*/
#link {
    bottom: 20px;
    color: #000;
    opacity: 0.2;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

#link p {
    margin: 0;
    margin-left: 5px;
}

#link:hover {
    opacity: 1;
}